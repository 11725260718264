<template>
  <div style="margin-top: 12vh; margin-bottom: 2.5rem">
    <div v-if="this.actualStep == 1" class="text-caminho2">
      <span class="caminho-subtitle">Seleção da Viagem ></span>
      <span class="caminho-subtitle1">Dados dos passageiros ></span>
      <span class="caminho-subtitle1">Pagamento ></span>
      <span class="caminho-subtitle1">Confirmação e Bilhetes</span>
    </div>
    <div v-if="this.actualStep == 2" class="text-caminho2">
      <span class="caminho-subtitle">Seleção da Viagem ></span>
      <span class="caminho-subtitle">Dados dos passageiros ></span>
      <span class="caminho-subtitle1">Pagamento ></span>
      <span class="caminho-subtitle1">Confirmação e Bilhetes</span>
    </div>
    <div v-if="this.actualStep == 3" class="text-caminho2">
      <span class="caminho-subtitle">Seleção da Viagem ></span>
      <span class="caminho-subtitle">Dados dos passageiros ></span>
      <span class="caminho-subtitle">Pagamento ></span>
      <span class="caminho-subtitle1">Confirmação e Bilhetes</span>
    </div>
    <div v-if="this.actualStep == 4" class="text-caminho2">
      <span class="caminho-subtitle">Seleção da Viagem ></span>
      <span class="caminho-subtitle">Dados dos passageiros ></span>
      <span class="caminho-subtitle">Pagamento ></span>
      <span class="caminho-subtitle">Confirmação e Bilhetes</span>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import ImageSearch from "@/assets/icons/caminho/search.svg";
import ImageCheck from "@/assets/icons/caminho/check.svg";
import ImageTicket from "@/assets/icons/caminho/ticket.svg";
import ImageShoppingCart from "@/assets/icons/caminho/shoppingcart.svg";

export default {
  name: "caminho",
  props: {
    actualStep: Number
  },
  data() {
    return {
      // Icons
      ImageSearch,
      ImageCheck,
      ImageTicket,
      ImageShoppingCart
    };
  }
};
</script>

<style lang="scss" scoped>
.text-caminho {
  margin-left: 10%;
  margin-bottom: 15px;
}
.text-caminho2 {
  margin-left: 5vw;
  margin-bottom: 15px;
}
// .caminho {
//   text-align: center;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   margin: auto;
//   background-color: rgba(255, 255, 255, 0.5);
//   max-width: 35rem;
//   // height: 2.5rem;
//   padding: 0.5rem;
//   border-radius: 1rem;
//   text-align: center;
//   align-items: center;
//   // box-shadow: 0px 5px 7px 3px #10101030;
// }

.item {
  border: 2px solid #344675;
  width: 0.75rem;
  height: 0.75rem;
  text-align: center;
  margin: auto;
  padding: 0.3rem;
  border-radius: 50%;
  margin-bottom: 0.1rem;
}

.caminho-icon {
  width: 100%;
  height: 100%;
  // filter: invert(100%);
}
.conjunto {
  text-align: center;
}

.caminho-subtitle1 {
  text-align: center;
  margin-left: 4px;
  font-family: "Roboto", sans-serif;
  color: #626262;
  font-size: 1.1rem;
}
.caminho-subtitle {
  margin-left: 4px;
  font-family: "Roboto", sans-serif;
  color: #009aa0;
  font-size: 1.1rem;
  font-weight: 550;
}

.line {
  border-top: 2px dashed #344675;
  border-bottom: 2px dashed #344675;
  width: 10%;
  vertical-align: middle;
}

.low-opacity {
  opacity: 0.2;
}

@media only screen and (max-width: 600px) {
  .caminho {
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 10px;
  }
}
</style>